@import "../../../designSystem/colors";

.findingDetails {
  grid-area: finding__details;
  padding-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-left: 1px solid $secondary-50-color;
  position: relative;
  &__frameworks {
    margin-bottom: 2rem;
  }
  &__property {
    margin-bottom: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid $secondary-30-color;
    & > :nth-child(1) {
      margin-bottom: 1rem;
    }
    p {
      white-space: pre-wrap;
    }
  }
  &__references {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    & > a {
      text-decoration: none;
      color: $accent-new-110-color;
      cursor: pointer;
      &:hover {
        color: $secondary-60-color;
      }
    }
  }
  &__frameworks_version {
    font-size: 16px;

    font-family: "basier_squaremedium", -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: normal;
  }
  &__frameworks_version_number {
    font-size: 16px;
    margin-top: 1rem;
    font-family: "basier_square", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-weight: normal;
  }
  &__frameworks_name {
    font-size: 16px;
    padding-left: 1rem;
    margin-top: 1rem;
    padding-bottom: 0.5rem;
    font-family: "basier_squaremedium", -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: normal;
  }
  &__frameworks_description {
    font-size: 16px;
    padding-left: 2rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  &__frameworks_attributes_label {
    padding-top: 0.5rem;
    font-size: 16px;
    font-family: "basier_squaremedium", -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: normal;
  }
  &__frameworks_attributes {
    padding-left: 2rem;
    padding-top: 0.5rem;
    & > :nth-child(1) {
      margin-bottom: 1rem;
    }
  }

  &__affected {
    display: flex;
    flex-direction: column;
    &__summary {
      margin-top: 2rem;
      padding-top: 1rem;
      border-top: 1px solid $secondary-30-color;
      display: grid;
      grid-template-columns: 1fr 2rem;
      padding-bottom: 1rem;
      & img {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: auto;
        &:hover {
          cursor: pointer;
          background-color: $secondary-10-color;
        }
      }
    }
    &__status {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
    &__list {
      margin-top: 2rem;
    }
    &__item {
      border-top: 1px solid $secondary-30-color;
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: grid;
      grid-template-columns: 80% 20%;
      & .chip {
        justify-self: end;
        align-self: start;
      }
    }
  }
  .has-tooltip {
    cursor: pointer;
  }
  .inspector-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    > p {
      margin-bottom: 10px;
    }
  }

  .inspectors-managment-button {
    margin-right: 10px;
    &-holder {
      display: flex;
      position: absolute;
      right: 0;
      margin-right: 24px;
      margin-top: 6px;
    }
  }
}
